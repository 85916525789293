/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import useAppTheme from "../../../hooks/useAppTheme";
import ROUTE_PATHS from "../../../routes/routesPath";
import {LinkGroup} from "../../../styles/styledComponent";
import {ReactComponent as OfferSymbol} from "../../../assets/Offers.svg";
import {ReactComponent as ProfilePic} from "../../../assets/profile_icon.svg";
import {ReactComponent as Collections} from "../../../assets/collections.svg";
import {ReactComponent as Loyalty} from "../../../assets/Loyalty.svg";
import CustomLogo from "../../../assets/CustomLogo.js";
import {Box} from "@mui/system";
import _, {get} from "lodash";
import {toast} from "react-hot-toast";
import {getInvoiceToken} from "../../../service/invoice";
import useAppConfig from "../../../hooks/useAppConfig";
import useAuth from "../../../hooks/useAuth";

export const isProfileSurveyEnabled = (pwaConfig) => {
    return get(pwaConfig, 'appConfig.config.profileSurvey.enabled', "false").toLowerCase() === "true"
}

export const onProfileClickRedirect = async (pwaConfig, decodedData) => {
    try {
        const link = get(pwaConfig, 'appConfig.config.profileSurvey.link', undefined)
        console.log("came here inside redirect", link, pwaConfig)
        if (!link) {
            toast.error("Profile Survey missing")
        }
        const payload = {
            tenant: _.get(decodedData, 'user.tenantId'),
            customerMobileNo: _.get(decodedData, 'user.mobile'),
            businessUnitId: _.get(pwaConfig, 'appConfig.selecteBussines.id')
        }
        const data = await getInvoiceToken(payload)
        const linkTag = document.createElement('a')
        linkTag.target = '_blank'
        linkTag.href = `${link}${
            Object.values(data)[0]
        }`
        return linkTag.click()
    } catch (err) {
        console.log('err', err)
    }
}

const ListOfLinks = ({
                         links,
                         component: Component = Link,
                         onLinkClick,
                         end = false,
                         isMobileView,
                     }) => {
    const [menuval, setMenuval] = useState([]);
    const {theme} = useAppTheme();
    const pwaConfig = useAppConfig();
    const decodedData = useAuth()
    let pathname = window.location.pathname;

    function setLink(navRoutes) {
        return Object.entries(links).map(([key, val]) => {
            const path = (val.module === 'HTML') ? `${navRoutes[val.module]}/${key}` : navRoutes[val.module];
            return {
                ...val,
                module: path,
            };
        });
    }

    useEffect(() => {
        let navRoutes = {
            OFFER: `${ROUTE_PATHS.OFFERS}/${ROUTE_PATHS.AVAILABLE_OFFERS}`,
            HOME: `${ROUTE_PATHS.HOME}`,
            REFER_A_FRIEND: `${ROUTE_PATHS.REFER_FRIEND}`,
            LOYALTY_DETAILS: `${ROUTE_PATHS.LOYALTY_INFO}`,
            TRANSACTION_HISTORY: `${ROUTE_PATHS.TRANSACTION_HISTORY}`,
            HTML: '/html'
        };

        const menus = async () => {
            const flipbook = get(pwaConfig, 'appConfig.config.flipbook', {})
            if (get(flipbook, 'enabled') === 'true'){
                const link = get(flipbook, 'link')
                const payload = {
                    tenant: _.get(decodedData, 'user.tenantId'),
                    contactNumber: _.get(decodedData, 'user.mobile')
                }
                const data = await getInvoiceToken(payload)
                const token = Object.values(data)[0];
                if (link){
                    navRoutes = {...navRoutes, FLIPBOOK: `${link}&dataToken=${token}`}
                }
            }
            const promise = await Promise.all(
                setLink(navRoutes)
            );
            setMenuval(promise);
        };
        menus();
    }, [links]);

    return (
        <LinkGroup sx={{m: 4}} color="black">
            {menuval &&
                menuval.map(({name, module}) => (
                    <li key={module + name}>
                            <Component onClick={onLinkClick} to={module} end={end} target={(name === 'Curated For You') ? '_blank' : '_self'}>
                                {isMobileView ? (
                                    <Box className="display">
                                        {name === "Offer for You" ? (
                                            <OfferSymbol/>
                                        ) : name === "My Profile" ? (
                                            <ProfilePic/>
                                        ) : name === "Loyalty Details" ? (
                                            <Loyalty className="margin"/>
                                        ) : name === "Curated For You" ? (
                                            <Collections className="margin"/>
                                        )  : (
                                            <CustomLogo text={name.substring(0, 1)}/>
                                        )}
                                        <span
                                            style={{
                                                fontWeight: 700,
                                                color: `${pathname === module ? theme.palette.primary.main : theme.palette.text.secondary}`,
                                                marginLeft: 5,
                                            }}
                                        >
                    {name}
                  </span>
                                    </Box>
                                ) : (
                                    <span
                                        style={{
                                            fontWeight: 700,
                                            color: `${pathname === module ? theme.palette.secondary.main : theme.palette.primary.main}`,
                                        }}
                                    >
                  {name}
                </span>
                                )}
                            </Component>
                    </li>
                ))}
        </LinkGroup>
    );
};

export default ListOfLinks;


